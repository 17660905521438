<template>
  <div class="automated-main">
    <div class="automated-payments">
      <div class="automated-payments-right-part forMobile">
        <img
          v-motion
          src="/img/automated-payment-img-min.png"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>

      <h4>Payments Built for Philippine Market</h4>
      <h1>Enable payments across all major<br />Philippine payment methods</h1>

      <div class="automated-payments-info">
        <div class="automated-payments-left-part">
          <li class="first-list-item">
            <p>
              We enable subscription payments via
              <span class="wordings">
                credit/debit card, GCash, GrabPay, Maya and bank transfer
              </span>
              with all major Philippine banks including BDO, BPI, Unionbank &
              more.
            </p>
          </li>
          <li class="first-list-item">
            <p>Accept payments on-site with HelixPay terminals.</p>
          </li>
        </div>

        <div class="automated-payments-right-part forWeb">
          <img
            v-motion
            src="/img/automated-payment-img-min.png"
            alt=""
            :initial="{
              opacity: 0,
              y: 100,
            }"
            :visibleOnce="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 100,
                stiffness: 200,
                damping: 90,
                mass: 3,
              },
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.forMobile {
  display: none;
}

.automated-main {
  width: 100%;
  background-color: #f3f7f8;
}

.automated-payments {
  width: 71rem;
  margin: auto;
  padding: 3rem 0;
  border-left: 2px solid white;
}

.automated-payments h4 {
  font-weight: 700;
  font-size: 1.5rem;
  padding-top: 3rem;
  padding-left: 30px;
  color: #25a4e1;
}

.automated-payments h1 {
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  margin-left: -2px;
  padding-left: 25px;
  border-left: 2px solid #25a4e1;
}

.wordings {
  font-weight: 600 !important;
  color: #25a4e1 !important;
}

.automated-payments-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  margin-top: 2rem;
  padding-bottom: 3.5rem;
  width: 95%;
  align-items: center;
  justify-content: center;
}

.first-list-item {
  list-style-type: disc;
  list-style-position: outside;
  padding: 20px 0 10px 20px;
}

.automated-payments-info p {
  line-height: 2;
  font-weight: 500;
  opacity: 0.6;
}

.automated-payments-left-part li {
  font-size: 1rem;
  top: -10rem;
  line-height: 2;
  margin-bottom: 20px;
  margin-right: 30px;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('/img/check-list.svg');
  background-repeat: no-repeat;
  background-position: 0% 5%;
  background-size: 1rem;
}

.automated-payments-left-part,
.automated-payments-right-part {
  width: 100%;
}

.automated-payments-right-part img {
  width: 80%;
  margin: auto;
  display: block;
}

@media only screen and (max-width: 1400px) {
  .automated-payments {
    width: 60rem;
  }

  .automated-payments-info {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 1050px) {
  .forMobile {
    display: block;
  }

  .forWeb {
    display: none;
  }

  .automated-payments {
    width: 40rem;
  }

  .automated-payments-info {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 650px) {
  .automated-payments {
    width: 90%;
    border-left: none;
  }

  .automated-payments-info {
    width: 100%;
    grid-template-columns: 1fr;
  }

  .automated-payments h4 {
    font-size: 1rem;
    padding-left: 0;
    margin-left: 0;
  }

  .automated-payments h1 {
    font-size: 1.5rem;
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }

  .automated-payments-left-part li {
    background-position: 0% 3%;
  }

  .automated-payments-info img {
    width: 80%;
    margin: auto;
  }

  .automated-payments-right-part img {
    margin-top: 2rem;
  }
}
</style>
