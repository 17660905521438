<template>
  <div class="customized-main">
    <div class="customized-subscriptions">
      <div class="customized-subscriptions-right-part forMobile">
        <img
          v-motion
          src="img/customize-event-tickets.png"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>

      <h4>Customize Event Tickets</h4>
      <h1>
        Power your ticket selling <br />
        with customized settings
      </h1>

      <div class="customized-subscriptions-info">
        <div class="customized-subscriptions-left-part">
          <li class="first-list-item">
            <p>
              Display special price discount option with extra discount for
              early bird customers or group tickets.
            </p>
          </li>
          <li class="first-list-item">
            <p>Customizable promo code vouchers for special discounts.</p>
          </li>
          <li class="first-list-item">
            <p>
              Use HelixPay’s no-code store builder tools or integrate into your
              existing website.
            </p>
          </li>
        </div>

        <div class="customized-subscriptions-right-part forWeb">
          <img
            v-motion
            src="img/customize-event-tickets.png"
            alt=""
            :initial="{
              opacity: 0,
              y: 100,
            }"
            :visibleOnce="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 100,
                stiffness: 200,
                damping: 90,
                mass: 3,
              },
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.forMobile {
  display: none;
}

.customized-main {
  width: 100%;
  background-color: #f3f7f8;
}

.customized-subscriptions {
  width: 71rem;
  margin: auto;
  border-left: 2px solid white;
}

.customized-subscriptions p {
  line-height: 2;
  font-weight: 500;
  opacity: 0.6;
}

.customized-subscriptions h4 {
  font-weight: 700;
  font-size: 1.5rem;
  padding-top: 3rem;
  padding-left: 30px;
  color: #25a4e1;
}

.customized-subscriptions h1 {
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  margin-left: -2px;
  padding-left: 25px;
  border-left: 2px solid #25a4e1;
}

.customized-subscriptions-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  margin-top: 1.5rem;
  padding-bottom: 2rem;
  width: 95%;
  align-items: center;
  align-content: center;
}

.customized-subscriptions-left-part li {
  font-size: 1rem;
  line-height: 2;
  top: -10rem;
  margin-bottom: 20px;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('/img/check-list.svg');
  background-repeat: no-repeat;
  background-position: 0% 25%;
  background-size: 1rem;
  text-indent: -0.1em;
}

.customized-subscriptions-right-part {
  width: 100%;
}

.customized-subscriptions-right-part img {
  width: 80%;
  height: 100%;
  margin: auto;
  display: block;
}

.customers-subscription-para {
  font-weight: 600;
  color: #25a4e1;
}

@media only screen and (max-width: 1400px) {
  .customized-subscriptions {
    width: 60rem;
  }
}

@media only screen and (max-width: 1050px) {
  .forMobile {
    display: block;
  }

  .forWeb {
    display: none;
  }

  .customized-subscriptions {
    width: 40rem;
  }

  .customized-subscriptions-info {
    grid-template-columns: 1fr;
  }

  .customized-subscriptions h4 {
    padding-top: 1rem;
  }
}

@media only screen and (max-width: 650px) {
  .customized-subscriptions {
    width: 90%;
    border-left: none;
  }

  .customized-subscriptions-info {
    width: 100%;
    grid-template-columns: 1fr;
  }

  .customized-subscriptions h4 {
    font-size: 1rem;
    padding-left: 0;
    margin-left: 0;
  }

  .customized-subscriptions h1 {
    font-size: 1.5rem;
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }

  .customized-subscriptions-left-part li {
    background-position: 0% 5%;
  }

  .customized-subscriptions-info img {
    width: 100%;
    margin-top: 2rem;
  }
}
</style>
