<template>
  <div class="sixth-section">
    <div class="sixth-section-info">
      <div class="sixth-section-right-part forMobile">
        <img
          v-motion
          src="/img/World_class_tech_01-min.png"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>

      <div class="sixth-section-left-part">
        <h2>World-class technology and secure payments experience</h2>
        <h4>
          Grow your revenue with confidence. HelixPay uses world-class
          technology and is fully compliant with Philippine regulations.
        </h4>
      </div>

      <div class="sixth-section-right-part forWeb">
        <img
          v-motion
          src="/img/World_class_tech_01-min.png"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.forMobile {
  display: none;
}

.sixth-section {
  width: 100%;
  background-color: #f3f7f8;
}

.sixth-section-info {
  width: 71rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
  border-left: 2px solid white;
}

.sixth-section-left-part p {
  line-height: 1.5;
  font-weight: 500;
  opacity: 0.6;
}

.first-list-item {
  list-style-type: disc;
  list-style-position: outside;
  padding: 20px 0 10px 20px;
}

.sixth-section-left-part h2 {
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-left: -2px;
  padding-left: 25px;
  border-left: 2px solid #25a4e1;
}

.sixth-section-left-part h4 {
  font-size: 1rem;
  font-weight: 700;
  opacity: 0.6;
  line-height: 2;
  margin-bottom: 2rem;
  margin-left: 1.45rem;
}

.sixth-section-left-part li {
  line-height: 2;
  font-size: 1rem;
  color: black;
  margin-bottom: 20px;
  margin-right: 30px;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('/img/check-list.svg');
  background-repeat: no-repeat;
  background-position: 0% 5%;
  background-size: 1rem;
  margin-left: 1.65rem;
}

.sixth-section-left-part li:nth-child(3) {
  margin-top: 2.5rem;
}

.sixth-section-right-part img {
  width: 90%;
  height: 100%;
  margin: auto;
  display: block;
}

@media only screen and (max-width: 1400px) {
  .sixth-section-info {
    width: 60rem;
  }
}

@media only screen and (max-width: 1050px) {
  .forMobile {
    display: block;
  }

  .forWeb {
    display: none;
  }

  .sixth-section-info {
    width: 40rem;
    grid-template-columns: 1fr;
  }

  .sixth-section-right-part {
    width: 80%;
    margin: auto;
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 650px) {
  .sixth-section-info {
    gap: 2rem;
    width: 90%;
    margin: auto;
    grid-template-columns: 1fr;
    border-left: none;
  }

  .sixth-section-info h2 {
    font-size: 1.5rem;
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }

  .sixth-section-info h4 {
    margin-left: 0;
    padding-left: 0;
  }

  .sixth-section-info li {
    font-weight: 400;
    margin-left: 0;
  }

  .sixth-section-right-part {
    width: 100%;
    margin: auto;
    margin-top: 2rem;
  }
}
</style>
