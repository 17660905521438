<template>
  <div class="support-main">
    <div class="support-ph">
      <div class="support-ph-right-part forMobile">
        <img
          v-motion
          src="img/helixpay-map-min.png"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>

      <div class="support-ph-info">
        <div class="support-ph-left-part">
          <h1>Here to support you in the Philippines</h1>
          <p class="support-para">
            HelixPay is built and headquartered in Manila. We’re here to support
            you to make sure your business thrives.
          </p>
        </div>

        <div class="support-ph-right-part forWeb">
          <img
            v-motion
            src="img/helixpay-map-min.png"
            alt=""
            :initial="{
              opacity: 0,
              y: 100,
            }"
            :visibleOnce="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 100,
                stiffness: 200,
                damping: 90,
                mass: 3,
              },
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.forMobile {
  display: none !important;
}

.support-main {
  width: 100%;
  background-color: #f3f7f8;
}

.support-ph {
  width: 71rem;
  margin: auto;
  padding-top: 2rem;
  border-left: 2px solid white;
}

.support-ph h1 {
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-left: -2px;
  padding-left: 28px;
  border-left: 2px solid #25a4e1;
}

.support-para {
  line-height: 2;
  width: 60%;
  font-size: 1rem;
  font-weight: 700;
  opacity: 0.5;
  margin-bottom: 2rem;
  margin-left: 1.9rem;
}

.support-ph-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  margin-top: 3.5rem;
  padding-bottom: 5rem;
  align-items: center;
  width: 95%;
}

.support-ph-info p {
  line-height: 2;
  font-weight: 500;
  opacity: 0.6;
}

.support-ph-left-part li {
  font-size: 1rem;
  line-height: 2;
  top: -10rem;
  margin-bottom: 20px;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('/img/check-list.svg');
  background-repeat: no-repeat;
  background-position: 0% 16%;
  background-size: 1rem;
  text-indent: -0.1em;
}

.support-ph-right-part {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.support-ph-right-part img {
  width: 60%;
  height: 100%;
  margin: auto;
  display: block;
}

@media only screen and (max-width: 1400px) {
  .support-ph {
    width: 60rem;
  }
}

@media only screen and (max-width: 1050px) {
  .forMobile {
    display: block !important;
  }

  .forWeb {
    display: none;
  }

  .support-ph {
    width: 40rem;
  }

  .support-ph-info {
    grid-template-columns: 1fr;
  }

  .support-para {
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .support-ph {
    width: 90%;
    border-left: none;
  }

  .support-ph-info {
    width: 100%;
    margin: 0;
    margin-top: 2rem;
  }

  .support-ph h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    margin-left: 0;
    padding-left: 0;
    line-height: 1.5;
    border-left: none;
  }

  .support-para {
    margin-left: 0;
    padding-left: 0;
  }

  .support-ph-left-part li {
    background-position: 0% 5%;
  }

  .support-ph-right-part img {
    margin-top: 1rem;
  }
}
</style>
